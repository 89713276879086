import { useEffect } from 'react'

interface Props<T> {
  onChange: (value: T, formikProps: any) => void
  value: T
  formikProps: any
}

export default function FormObserver<T>(props: Props<T>) {
  const stringifiedValues = JSON.stringify(props.value)

  useEffect(() => {
    props.onChange(props.value, props.formikProps)
  }, [props, stringifiedValues])
  return null
}

FormObserver.defaultProps = {
  onChange: () => null,
}
