import React, { useState } from 'react'
import {
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react'
import styled from 'styled-components'
import { Search } from 'react-feather'
import { getInitials } from '../../../lib/dealUtils'
import CircularBadge from '../../Badges/CircularBadge'

const Option = styled.div<any>`
  height: 40px;
  min-height: 40px;
  font-size: 13px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => (props.isSelected ? '#f8f8f8' : '')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};

  opacity: ${props => (props.isDisabled ? '0.4' : '1')};
  &:hover {
    background-color: #f8f8f8;
  }
`

export const Pill = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  margin-left: 5px;
  background-color: ${props => props.color || '#e0e0e0'};
  border-radius: 6px;
  font-size: 12px;
  &:first-child {
    margin-left: 0;
  }
`

const Selection = styled.div<any>`
  background-color: white;
  border: 1px solid rgb(226, 232, 240);
  min-width: 250px;
  min-height: 48px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: ${props => (props.showPlaceholder ? 'flex-start' : 'left')};
  flex-wrap: wrap;
  padding: 5px;
  &:hover {
    border-color: black;
    cursor: pointer;
  }
  ::placeholder {
    font-size: 15px;
  }
`

export const PillsWrapper = styled.td`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
`

const TeamMultiSelect = ({
  teams,
  selections,
  onSelect,
  teamsToColors,
  menuWidth = '433px',
  fontColor = '#A7B5C4',
  fontSize = '14px',
  fontWeight = 400,
}: any) => {
  const [term, setTerm] = useState('')

  const filteredTeams = teams.filter((team: any) =>
    team?.name.toLowerCase().includes(term.toLowerCase()),
  )

  return (
    <Popover placement='bottom'>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Selection
              showPlaceholder={selections.length === 0}
              selections={selections}
            >
              {selections.length > 0 ? (
                <PillsWrapper>
                  {selections.map((selection: any, index: any) => {
                    const team =
                      selection === 'orgWide'
                        ? { id: 'orgWide', name: 'All Users' }
                        : teams.find((team: any) => {
                            return team.id === selection
                          })

                    const zIndex = 0
                    const overlapMargin = -5

                    return (
                      <Pill
                        key={selection}
                        color={'#F0F0F0'}
                        style={{
                          marginLeft: index === 0 ? 0 : `5px`,
                          zIndex: zIndex,
                        }}
                      >
                        <CircularBadge
                          initials={getInitials(team.name)}
                          color={teamsToColors[team.id] || 'black'}
                          size='xs'
                          style={{ color: 'white' }}
                        />
                        <span style={{ marginLeft: '8px', fontSize: '13px' }}>
                          {team.name}
                        </span>
                      </Pill>
                    )
                  })}
                </PillsWrapper>
              ) : (
                <div
                  style={{
                    color: fontColor,
                    fontSize: fontSize,
                    marginLeft: '13px',
                    marginTop: '8px',
                    fontWeight: fontWeight,
                  }}
                >
                  {'Select teams'}
                </div>
              )}
            </Selection>
          </PopoverTrigger>
          <PopoverContent
            marginTop={'5px'}
            width={menuWidth}
            maxHeight={'345px'}
            boxShadow='0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)'
            overflowY='auto'
          >
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <Search height={15} width={15} color='#858585' />
              </InputLeftElement>
              <Input
                value={term}
                onChange={e => {
                  setTerm(e.target.value)
                }}
                sx={{
                  _focus: {
                    boxShadow: 'none',
                    borderColor: 'rgb(226, 232, 240)',
                  },
                  _hover: {
                    boxShadow: 'none',
                    borderColor: 'rgb(226, 232, 240)',
                  },
                }}
                borderRadius={'none'}
                borderTop='none'
                borderLeft='none'
                borderRight='none'
                fontSize='14px'
                placeholder='Search'
              />
            </InputGroup>
            {[{ id: 'orgWide', name: 'All Users' }, ...filteredTeams].map(
              (team: any) => {
                const isDisabled =
                  selections.length > 0
                    ? selections.includes('orgWide')
                      ? team.id !== 'orgWide'
                      : team.id === 'orgWide'
                    : false

                return (
                  <Option
                    key={team.id}
                    onClick={() => {
                      if (!isDisabled) {
                        onSelect(team.id)
                        if (
                          team.id === 'orgWide' &&
                          !selections.includes('orgWide')
                        ) {
                          onClose()
                        }
                      }
                    }}
                    isSelected={selections.includes(team.id)}
                    isAllSelected={selections.includes('orgWide')}
                    isDisabled={isDisabled}
                  >
                    <div>
                      <CircularBadge
                        initials={getInitials(team.name)}
                        color={teamsToColors[team.id] || 'black'}
                        size='sm'
                        style={{ color: 'white' }}
                      />
                      <span
                        style={{
                          paddingLeft: '10px',
                          position: 'absolute',
                          marginTop: '2px',
                        }}
                      >
                        {team.name}
                      </span>
                    </div>
                    <Checkbox
                      onChange={() => {
                        onSelect(team.id)
                      }}
                      isChecked={selections.includes(team.id)}
                      sx={{
                        '.chakra-checkbox__control': {
                          height: '18px',
                          width: '18px',
                          borderRadius: '3px',
                          borderColor: 'black',
                          _checked: {
                            bg: 'black',
                            color: 'white',
                            borderColor: 'black',

                            svg: {
                              transform: 'scale(1.3)',
                            },

                            _hover: {
                              bg: 'black',
                              borderColor: 'black',
                            },
                          },
                          _hover: {
                            borderColor: 'black',
                          },
                          _focus: {
                            boxShadow: 'none',
                          },
                        },
                      }}
                    />
                  </Option>
                )
              },
            )}
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default TeamMultiSelect
