import { Formik, Form as FormikForm, FormikValues } from 'formik'
import { ReactNode } from 'react'
import * as yup from 'yup'
import FormObserver from './FormObserver'

export type FormProps = {
  initialValues?: FormikValues
  validationSchema?: yup.Schema
  validate?: (values: any) => void
  onSubmit: (values: any, actions: any) => Promise<void>
  children?: (props: any) => ReactNode | Array<ReactNode>
  onChange?: (e: any, formikProps: any) => void
  onFormik?: (e: any) => void
  className?: string
}

export const Form = <T,>(props: FormProps) => {
  return (
    <Formik
      {...{
        initialValues:
          (props.initialValues as FormikValues) || ({} as FormikValues),
        validationSchema: props.validationSchema,
        onSubmit: props.onSubmit,
        validate: props.validate,
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true,
        enableReinitialize: true,
      }}
    >
      {({ handleSubmit, values, errors, setSubmitting, ...formikProps }) => {
        return (
          <FormikForm
            className={props.className}
            onSubmit={async () => {
              setSubmitting(true)
              await handleSubmit()
              setSubmitting(false)
            }}
          >
            {props.children &&
              (props.children({
                handleSubmit,
                values,
                errors,
                ...formikProps,
              }) as any)}
            <FormObserver
              value={values as any}
              formikProps={formikProps}
              onChange={(values: any, formikProps: any) =>
                props.onChange && props.onChange(values, formikProps)
              }
            />
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default Form
