import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Flex,
  useToast,
  NumberInputField,
  NumberInput,
  Box,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Api from '../../lib/api'
import { CreatableSelect, Select } from 'chakra-react-select'
import { DropdownIndicator } from '../InputsV2/Select'
import styled from 'styled-components'

export const baseStyles = ({
  color,
  backgroundColor,
  height,
  fontSize,
  shouldApplyMaxHeight,
}: any) => {
  return {
    control: (styles: any) => ({
      ...styles,
      background: backgroundColor,
      border: '1px solid black',
      minHeight: height,
      ...(shouldApplyMaxHeight && { maxHeight: height }),
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      borderRadius: '6px',
      fontSize: fontSize,
      width: '100%',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 12px 0px',
      borderRadius: '6px',
      fontSize: fontSize,
      backgroundColor: 'white',
      overflow: 'hidden',
      marginTop: fontSize,
      width: '100%',
      marginLeft: '1px',
    }),
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        maxWidth: '97%',
        backgroundColor: isSelected
          ? 'rgb(248, 248, 248)'
          : isFocused
          ? 'rgb(248, 248, 248)'
          : 'white',
        _hover: {
          backgroundColor: 'rgb(248, 248, 248)',
        },
        width: 'calc(100% - 14px)',
        marginLeft: '7px',
        marginRight: '7px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontSize: '13px',
        color: '#646464',
        fontWeight: '500',
        height: '35px',
      }
    },
    inputContainer: (styles: any) => ({
      ...styles,
      color: 'black',
      width: '100%',
      fontSize: fontSize,
      paddingLeft: '16px',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: color,
      width: '100%',
      fontSize: fontSize,
      paddingLeft: '16px',
    }),
    multiValue: (styles: any) => ({
      ...styles,
      background: 'rgb(248, 248, 248)',
      color: '#646464',
      fontSize: '15px',
      margin: '4px',
      paddingLeft: '16px',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
    }),

    container: (styles: any) => ({
      ...styles,
      height: 'fit-content',
      width: '100%',
      minHeight: '32px',
      borderRadius: '0px',
      marginLeft: '0.5px',
      marginRight: '0.5px',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    valueContainer: (styles: any, data: any) => {
      return {
        ...styles,
        padding: 0,
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '0.8rem',
        color: 'black',
        '&:focus': {
          outline: 'none',
          border: 'none',
          boxShadow: 'none',
        },
        ...(data.isMulti && {
          marginLeft: '4px',
          marginTop: '4px',
        }),
      }
    },
    input: (styles: any) => ({
      ...styles,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      paddingLeft: '16px',
      fontSize: fontSize,
    }),
  }
}

type Option = {
  label: string
  value: string
}

type Props = {
  isOpen: boolean
  objectName: string
  selectedItem: any
  onClose: () => void
  onSuccess: (recordTypeId: string) => void
}

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const Label = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 30px;
  color: #646464;
`

export default function MarketplaceConfigurationModal(props: Props) {
  const { isOpen, onClose, selectedItem, onSuccess, objectName } = props

  const toast = useToast()

  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [additionalOptions, setAdditionalOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [selectedOption, setSelectedOption] = useState<Option | null>(null)

  const [selectedAdditionalOption, setSelectedAdditionalOption] =
    useState<Option | null>(null)

  const [reportFolders, setReportFolders] = useState<any>()

  useEffect(() => {
    Api.salesforce.objects
      .fetchRecordTypes({ objectName })
      .then(
        (data: { recordTypes: Array<any>; businessProcesses: Array<any> }) => {
          if (data.recordTypes?.length > 0) {
            setOptions(
              data.recordTypes.map(recordType => ({
                label: recordType.name,
                value: recordType.id,
              })),
            )
          }

          if (data.businessProcesses.length > 0) {
            setAdditionalOptions(
              data.businessProcesses.map(salesProcess => {
                return { label: salesProcess.name, value: salesProcess.id }
              }),
            )
          }
        },
      )
  }, [objectName])

  useEffect(() => {
    if (
      !!selectedItem?.client_config &&
      !!selectedItem?.client_config['default_value']
    ) {
      setSelectedOption({
        label: '',
        value: selectedItem.client_config['default_value'],
      })
    }

    if (selectedItem?.item_type === 'Report') {
      fetchReportFolders()
    }
  }, [selectedItem])

  const fetchReportFolders = () => {
    Api.salesforce.reportFolders.getReportFolders({}).then(data => {
      setReportFolders(
        data.folders.map((folder: any) => {
          return {
            label: new DOMParser().parseFromString(folder.label, 'text/html')
              .documentElement.textContent,
            value: folder.name,
          }
        }),
      )
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSelectedOption(null)
        setSelectedAdditionalOption(null)
        onClose()
      }}
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        minHeight={'350px'}
        minWidth={'500px'}
        borderRadius={'10px'}
        style={{ marginTop: '200px' }}
      >
        <ModalBody style={{ margin: '3px', marginTop: '5px' }}>
          <Title>
            {selectedItem?.item_type === 'Report'
              ? `Select Folder for ${selectedItem?.item_metadata['name']}`
              : `${selectedItem?.item_metadata['name']}`}
          </Title>
          {selectedItem?.item_type === 'Report' ? (
            <Box
              style={{
                marginTop: '40px',
                backgroundColor: 'rgb(248, 248, 248)',
                borderRadius: '10px',
                paddingBottom: '10px',
                paddingTop: '15px',
                paddingLeft: '18px',
                paddingRight: '18px',
              }}
            >
              <FormControl pt={3} isRequired>
                <Label>Select the folder to save this report to </Label>

                <Select
                  chakraStyles={baseStyles({
                    color: 'black',
                    backgroundColor: 'white',
                    height: '40px',
                    fontSize: '13px',
                  })}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: DropdownIndicator,
                  }}
                  placeholder='Select folder'
                  onChange={(selection: any) => {
                    setSelectedOption({
                      label: selection.value,
                      value: selection.value,
                    })
                  }}
                  options={reportFolders}
                />
              </FormControl>
            </Box>
          ) : (
            <>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: '#646464',
                  marginLeft: '2px',
                  marginTop: '4px',
                }}
              >
                {`Define ${selectedItem?.client_config['client_field']}`}
              </div>
              <VStack
                alignItems='flex-start'
                style={{
                  marginTop: '40px',
                  backgroundColor: 'rgb(248, 248, 248)',
                  borderRadius: '10px',
                  paddingBottom: '10px',
                  paddingTop: '15px',
                  paddingLeft: '18px',
                  paddingRight: '18px',
                }}
              >
                {selectedItem?.client_config['client_field'] ===
                'Number of Days' ? (
                  <FormControl>
                    <Label>
                      Enter the number of days to set the closing date from the
                      opportunity creation date.
                    </Label>

                    <NumberInput
                      backgroundColor={'white'}
                      focusBorderColor='none'
                      sx={{
                        '.chakra-numberinput__field:focus': {
                          borderColor: 'black',
                          borderWidth: '0.5px',
                        },
                      }}
                      value={selectedOption?.value}
                      onChange={e => {
                        setSelectedOption({
                          label: '',
                          value: e,
                        })
                      }}
                    >
                      {' '}
                      <NumberInputField></NumberInputField>
                    </NumberInput>
                  </FormControl>
                ) : (
                  <>
                    {' '}
                    <FormControl isRequired py={3}>
                      <Label>
                        Please select the Record Type to be used to create the
                        new opportunity or create one.
                      </Label>
                      <CreatableSelect
                        options={options}
                        value={selectedOption}
                        chakraStyles={baseStyles({
                          color: 'black',
                          backgroundColor: 'white',
                          height: '40px',
                          fontSize: '13px',
                        })}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: DropdownIndicator,
                        }}
                        onChange={e => {
                          setSelectedOption(e)
                        }}
                        placeholder='Select record type...'
                        onCreateOption={value => {
                          if (
                            additionalOptions.length === 0 &&
                            objectName !== 'Opportunity'
                          ) {
                            Api.salesforce.objects
                              .createRecordType({
                                objectName,
                                recordTypeName: value,
                              })
                              .then(data => {
                                if (data.success) {
                                  Api.salesforce.objects
                                    .fetchRecordTypes({ objectName })
                                    .then(
                                      (data: { recordTypes: Array<any> }) => {
                                        if (data.recordTypes?.length > 0) {
                                          setOptions(
                                            data.recordTypes.map(
                                              recordType => ({
                                                label: recordType.name,
                                                value: recordType.id,
                                              }),
                                            ),
                                          )
                                        }
                                      },
                                    )
                                }
                              })
                          } else {
                            setOptions(prev => [
                              ...prev,
                              { label: value, value: 'new' },
                            ])

                            setSelectedOption({
                              label: value,
                              value: 'new',
                            })
                          }
                        }}
                      />
                    </FormControl>
                    {additionalOptions.length > 0 &&
                      selectedOption?.value === 'new' && (
                        <FormControl isRequired py={3}>
                          <Label>
                            Please select a business process to associate this
                            new record type with.
                          </Label>

                          <Select
                            options={additionalOptions}
                            value={selectedAdditionalOption}
                            chakraStyles={baseStyles({
                              color: 'black',
                              backgroundColor: 'white',
                              fontSize: '13px',
                            })}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator: DropdownIndicator,
                            }}
                            onChange={e => {
                              setSelectedAdditionalOption(e)
                            }}
                            placeholder='Select business process...'
                          />
                        </FormControl>
                      )}
                  </>
                )}
              </VStack>
            </>
          )}
        </ModalBody>
        <ModalFooter sx={{ paddingTop: 0, borderTop: 0, marginTop: '50px' }}>
          <Flex width='full' justifyContent='flex-end' alignItems='center'>
            <Button
              variant='primaryCTA'
              size='sm'
              isDisabled={
                objectName === 'Opportunity'
                  ? selectedOption?.value !== 'new'
                    ? !selectedOption?.value
                    : !selectedAdditionalOption?.value
                  : !selectedOption?.value
              }
              onClick={() => {
                // Create Record Type if new
                if (selectedOption?.value === 'new') {
                  let errors = []

                  Api.salesforce.objects
                    .createRecordType({
                      objectName,
                      recordTypeName: selectedOption.label,
                      businessProcess: selectedAdditionalOption?.label,
                    })
                    .then(data => {
                      if (data.success) {
                        toast({
                          description: `Successfully added new record type ${selectedOption.label}`,
                          status: 'success',
                          variant: 'subtle',
                          position: 'bottom-right',
                        })

                        onClose()

                        onSuccess(data.id)

                        setSelectedOption(null)
                        setSelectedAdditionalOption(null)
                      } else if (data.errors.length > 0) {
                        errors = data.errors.map((error: any) => error.message)

                        toast({
                          description: `Error creating record type: ${errors.join(
                            ', ',
                          )}`,
                          status: 'error',
                          variant: 'subtle',
                          position: 'bottom-right',
                        })
                      }
                    })
                } else {
                  onClose()

                  onSuccess(selectedOption?.value ?? '')
                  setSelectedOption(null)
                  setSelectedAdditionalOption(null)
                }
              }}
            >
              Deploy
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
