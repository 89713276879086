import { property, styles, tw } from '@minupalaniappan/brise'
import { Ref } from 'react'
import { ContainerProps } from '../../types'
import { Gap, GapProps, SpacingProps, SpacingX, SpacingY } from './Spacing'

export type RowXType = 'left' | 'center' | 'right'
export type RowYType = 'top' | 'center' | 'bottom'

export const RowXPosition: Array<RowXType> = ['left', 'center', 'right']
export const RowYPosition: Array<RowYType> = ['top', 'center', 'bottom']

export type VisibilityRowProps = {
  visible?: boolean
}

export type RowProps = {
  x?: RowXType
  y?: RowYType
  between?: boolean
  disabled?: boolean
  around?: boolean
  grow?: boolean
  hGrow?: boolean
  onClick?: (e: any) => void
  forwadedRef?: Ref<HTMLDivElement>
} & ContainerProps &
  SpacingProps &
  GapProps

export const RowXPositionStyle = property(
  'x',
  {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  },
  'left',
)

export const RowYPositionStyle = property(
  'y',
  {
    top: 'items-start',
    center: 'items-center',
    bottom: 'items-end',
  },
  'top',
)

export const RowAdditionalStyles = styles`
  ${(props: RowProps) => (props.between ? 'justify-between' : '')}
  ${(props: RowProps) => (props.around ? 'justify-around' : '')}
  ${(props: RowProps) => (props.grow ? 'grow w-full' : 'w-fit')}
  ${(props: RowProps) => (props.hGrow ? 'h-full' : '')}
`

export const Row = tw(
  ({
    className,
    children,
    onClick,
    forwadedRef,
    onMouseEnter,
    onMouseLeave,
  }: RowProps) => {
    return (
      <div
        {...{
          onClick: onClick,
          onMouseEnter: onMouseEnter,
          onMouseLeave: onMouseLeave,
          ref: forwadedRef,
          className,
        }}
      >
        {children}
      </div>
    )
  },
)`
  flex
  !flex-row
  ${RowXPositionStyle}
  ${RowYPositionStyle}
  ${RowAdditionalStyles}
  ${Gap}
  ${SpacingX}
  ${SpacingY}
  ${({ disabled }) => (disabled ? 'pointer-events-none opacity-50' : '')}
`

export default Row
